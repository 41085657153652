























import {Vue, Component, Emit} from "vue-property-decorator";
import SiteOrderType from "./SiteOrder";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ShopAdminTitle from "@/views/ShopAdmin/components/Title/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import SiteOrderItem from "@/views/ShopAdmin/SiteOrder/components/Item/index.vue";
import {navList} from "@/views/ShopAdmin/SiteOrder/Model/index";
import {getOrderNum} from "@/views/ShopAdmin/SiteOrder/Server";

@Component({name: "SiteOrder",components:{ HeadTop,ShopAdminTitle,PullDownUpList,ListBottom,SiteOrderItem }})
export default class SiteOrder extends ZoomPage implements SiteOrderType{
    navList = navList
    PullDown = false
    StopUp = false
    UpDownBool = true
    List:any[] = []
    orderList:any[] = []

    mounted(){
        this.loadingShow = false
        this.handleUpData()
    }

    handlePullDown(): void {
        this.PullDown = true
        this.handleUpData()
    }

    handleUpDown(): void {
        //
    }

    handleUpData(bool?: boolean): void {
        getOrderNum(this.$route.query.areaId as string).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            this.navList = res.stateList
            this.orderList = res.orderList
            this.List = res.deliveryInfo
        })
    }
}
