















import {Vue, Component, Prop} from "vue-property-decorator";
import SiteOrderItemType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";

@Component({name: "SiteOrderItem",components:{ ScrollViewX }})
export default class SiteOrderItem extends Vue implements SiteOrderItemType{
    rightIcon = require("@/assets/icon/Basics/Right.png");
    testList = [ "01","黄焖鸡","8","6","2","12", ]

    handleToPath(){
        //
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index || 0 }
}
